import { calls, endpoints } from '@/services/calls';
import { dispatchNotif } from '@/services/notifications';

const upload = {
  namespaced: true,
  state: {
    visible: false,
    files: [],
    file: null,
    legalcase: null,
    metadatas: {
      name: null,
      type: null,
      visibility: 'public',
      country: null,
      anonymous: false,
      source: '',
      lawcases: []
    },
    requests: 0
  },
  mutations: {
    toggle(state) {
      state.visible = !state.visible;
    },
    updateFile(state, file) {
      state.file = file;
    },
    addFile(state, file) {
      state.files.push(file);
    },
    updateLegalcase(state, token) {
      state.legalcase = token;
    },
    updateFiles(state, files) {
      state.files = files;
    },
    updateMetadata(state, { type, value }) {
      state.metadatas[type] = value;
    },
    updateMetadataArray(state, { type, item }) {
      const ids = state.metadatas[type].map(el => el.id);
      const index = ids.indexOf(item.id);

      if (index >= 0) {
        state.metadatas[type].splice(index, 1);
        return;
      }

      state.metadatas[type].push(item);
    },
    reset(state) {
      state.file = null;
      state.files = [];
      state.metadatas = {
        name: null,
        type: null,
        visibility: 'public',
        country: null,
        anonymous: false,
        lawcases: [],
        //
        source: '',
      }
    },
    incrementRequests(state) {
      state.requests++;
    },
    decrementRequests(state) {
      state.requests--;
    },
  },
  actions: {
    async uploadFile({ state, commit, dispatch }) {
      commit('incrementRequests');
      const { file, metadatas } = state;
      const formData = new FormData();
      formData.append('file', file);
      let request = null;
      let req = null;

      try {
        request = await calls.post('uploadDoc', formData);
        const { data } = request;

        if (data.token && metadatas) {
          const options = {
            token: data.token,
            metadata: metadatas
          };
    
          if (metadatas.name && metadatas.name.length) {
            options.name = metadatas.name
          }
    
          req = await calls.post('docUpdateLabelling', options);
        }
      } catch (error) {
        console.log('error :', error);
      }

      if (req.status === 'success') {
        dispatchNotif({ type: 'success', text: `Le document a bien été uploadé` });
      }

      commit('decrementRequests');
      return request;
    },
    async uploadFiles({ state, commit, dispatch }) {
      commit('incrementRequests');
      const { files, metadatas } = state;
      const success = [];
      const errors = [];

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        // const request = await uploadFile(file, metadatas);
        const formData = new FormData();
        formData.append('file', file);
        let request = null;
        let req = null;

        try {
          request = await calls.post('uploadDoc', formData);
          const { data } = request;
  
          if (data.token && metadatas) {
            const options = {
              token: data.token,
              metadata: metadatas
            };
      
            if (metadatas.name && metadatas.name.length) {
              options.name = metadatas.name
            }
      
            req = await calls.post('docUpdateLabelling', options);
          }
        } catch (error) {
          console.log('error :', error);
        }

        if (request.status === 'success') {
          if (state.legalcase) {
            const docToken = request.data.token;
            const url = endpoints.addDocToLegalCase
              .replace('{legalcase}', state.legalcase)
              .replace('{doc}', docToken);
            const addRequest = await calls.post(url);
            // const addRequest = await addDocToCase(state.legalcase, docToken);

            if (addRequest.status === 'error') {
              dispatchNotif({ type: 'success', text: `"${request.data.name}" a bien été uploadé mais une erreur est survenue lors de l'ajout à l'affaire` });
            }
          }

          success.push(file);
        } else {
          errors.push(file);
        }
      }
      // if (request.status === 'success') {}
      if (success.length) {
        dispatchNotif({ type: 'success', text: `${success.length} document${success.length > 1 ? 's' : ''} uploadé${success.length > 1 ? 's' : ''}` });
      }

      if (errors.length) {
        const names = errors.map(f => f.name);
        dispatchNotif({ type: 'alert', text: `L'upload de ${errors.length} document${errors.length > 1 ? 's' : ''} a échoué : ${names.join('<br>')}` });
      }

      commit('decrementRequests');
      return {
        status: success.length ? 'success' : 'errors'
      };
    },
  }
}

export default upload;
