<template>
  <div class="form metadataform">
    <!-- Main datas -->
    <FormRow>
      <template v-if="full">
        <Input
          :label="'Nom du document'"
          :value="metadatas.name"
          :id="'metadatas-name'"
          @onInput="onChange($event, 'name')"
        />
        <Select
          :label="'Type'"
          :options="types"
          :value="metadatas.type"
          :allowNull="true"
          :placeholder="'Sélectionner un type'"
          :hasSearch="true"
          @onChange="onChange($event, 'type')"
        />
        <Select
          :label="'Nationalité'"
          :options="countries"
          :value="metadatas.country"
          :allowNull="true"
          :placeholder="'Sélectionner un pays'"
          :hasSearch="true"
          @onChange="onChange($event, 'country')"
        />
        <Select
          :label="'Langue'"
          :options="languages"
          :value="metadatas.language"
          :allowNull="true"
          :placeholder="'Sélectionner une langue'"
          :hasSearch="true"
          @onChange="onChange($event, 'language')"
        />

        <Radio
          :label="'Source'"
          :options="sources"
          :name="'document-source'"
          :value="metadatas.source"
          @onChange="onChange($event, 'source')"
        />

        <div v-if="metadatas.source === 'symplicy'" class="empty"></div>

        <Select
          v-if="metadatas.source === 'user'"
          :label="'User source'"
          :options="users"
          :value="userSourceID"
          :allowNull="true"
          :hasSearchApi="true"
          :placeholder="lawyerSourceName ? lawyerSourceName : 'Sélectionner un utilisateur'"
          :loading="usersLoad"
          @onSearch="onUserSearch($event)"
          @onChange="onUserChange($event)"
        />
        <Select
          v-if="metadatas.source === 'lawyer'"
          :label="'Avocat source'"
          :options="lawyers"
          :value="lawyerSourceID"
          :allowNull="true"
          :hasSearchApi="true"
          :placeholder="lawyerSourceName ? lawyerSourceName : 'Sélectionner un avocat'"
          :loading="lawyersLoad"
          @onSearch="onLawyerSearch($event)"
          @onChange="onLawyerChange($event)"
        />
        <!-- <Input
          :label="'Avocat source'"
          :value="metadatas.lawyerSource"
          :id="'metadatas-lawyer'"
          :disabled="metadatas.source === 'symplicy'"
          @onInput="onChange($event, 'lawyerSource')"
        /> -->
      </template>

      <Radio
        :label="'Confidentialité'"
        :options="displayedVisibilities"
        :name="'document-visibility'"
        :value="metadatas.visibility"
        @onChange="onChange($event, 'visibility')"
      />
    </FormRow>

    <template v-if="full">
      <FormRow :columns="1">
        <Select
          :label="'Cas concret'"
          :options="lawcases"
          :value="lawcasesID"
          :allowNull="true"
          :hasSearchApi="true"
          :placeholder="'Sélectionner un ou plusieurs cas concret(s)'"
          :loading="lawcasesLoad"
          :multiple="true"
          @onSearch="onLawcaseSearch($event)"
          @onChange="onLawcaseChange($event)"
        />
        <Pills
          v-if="metadatas.lawcases && metadatas.lawcases.length"
          :els="metadatas.lawcases"
          :property="'content'"
          :remove="true"
          @remove="onLawcaseChange($event.id)"
        />
      </FormRow>

      <!-- BE - Legislation -->
      <template v-if="metadatas.country === 'be' && metadatas.type === 'legislation'">
        <hr/>
        <FormRow>
          <Input
            :label="'Intitulé'"
            :id="'metadatas-title'"
            :value="metadatas.title"
            @onInput="onChange($event, 'title')"
          />
          <Select
            :label="'Nature'"
            :options="be.legislation.natures"
            :value="metadatas.nature"
            :placeholder="'Sélectionner la nature'"
            :allowNull="true"
            :hasSearch="true"
            @onChange="onChange($event, 'nature')"
          />
          <Input
            :label="'Date'"
            :type="'date'"
            :id="'metadatas-legislation-date'"
            :value="metadatas.legislationDate"
            @onInput="onChange($event, 'legislationDate')"
          />
          <!-- <Input
            :label="'Recueil officiel'"
            :id="'metadatas-collection'"
            :value="metadatas.collection"
            @onInput="onChange($event, 'collection')"
          /> -->
          <Select
            :label="'Recueil officiel'"
            :options="be.legislation.collections"
            :value="metadatas.collection"
            :placeholder="'Sélectionner le recueil'"
            :allowNull="true"
            @onChange="onChange($event, 'collection')"
          />
          <Input
            :label="'Date de publication'"
            :type="'date'"
            :id="'metadatas-publication-date'"
            :value="metadatas.publicationDate"
            @onInput="onChange($event, 'publicationDate')"
          />
          <Input
            :label="'Renvoie surabondant à un autre recueil'"
            :id="'metadatas-linkedCollection'"
            :value="metadatas.linkedCollection"
            @onInput="onChange($event, 'linkedCollection')"
          />
        </FormRow>
      </template>

      <!-- BE - Jurisprudence -->
      <template v-if="metadatas.country === 'be' && metadatas.type === 'jurisprudence'">
        <hr/>
        <FormRow>
          <Select
            :label="'Type de juridiction'"
            :options="be.jurisprudence.jurisdictionsType"
            :value="metadatas.jurisdictionType"
            :placeholder="'Sélectionner le type de juridiction'"
            :allowNull="true"
            :hasSearch="true"
            @onChange="onChange($event, 'jurisdictionType')"
          />
          <Select
            v-if="metadatas.jurisdictionType && metadatas.jurisdictionType === 'trib'"
            :label="'Section du tribunal'"
            :options="be.jurisprudence.jurisdictionsSection"
            :value="metadatas.jurisdictionSection"
            :placeholder="'Sélectionner la section de la juridiction'"
            :allowNull="true"
            :hasSearch="true"
            @onChange="onChange($event, 'jurisdictionSection')"
          />
          <!-- :options="be.jurisprudence.jurisdictionsName" -->
          <Select
            :label="'Nom de la juridiction'"
            :options="jurisdictionsName"
            :value="metadatas.jurisdictionName"
            :placeholder="'Sélectionner le nom de la juridiction'"
            :allowNull="true"
            :hasSearch="true"
            @onChange="onChange($event, 'jurisdictionName')"
          />
          <Select
            v-if="metadatas.jurisdictionType === 'adm' && metadatas.jurisdictionName === 'adp'"
            :label="'Auteur de la décision'"
            :options="be.jurisprudence.jurisdictionsAuthor"
            :value="metadatas.jurisdictionAuthor"
            :placeholder="'Sélectionner l\'auteur de la décision'"
            :allowNull="true"
            :hasSearch="true"
            @onChange="onChange($event, 'jurisdictionAuthor')"
          />
          <Input
            :label="'Date du prononcé'"
            :type="'date'"
            :id="'metadatas-pronouncedDate'"
            :value="metadatas.pronouncedDate"
            @onInput="onChange($event, 'pronouncedDate')"
          />
          <Input
            :label="'Numéro de chambre'"
            :id="'metadatas-roomNumber'"
            :value="metadatas.roomNumber"
            @onInput="onChange($event, 'roomNumber')"
          />
          <Input
            :label="'Nature de la chambre'"
            :id="'metadatas-roomNature'"
            :value="metadatas.roomNature"
            @onInput="onChange($event, 'roomNature')"
          />
          <Input
            :label="'Numéro officiel'"
            :id="'metadatas-number'"
            :value="metadatas.number"
            @onInput="onChange($event, 'number')"
          />
          <Input
            :label="'Numéro de role'"
            :id="'metadatas-number'"
            :value="metadatas.roleNumber"
            @onInput="onChange($event, 'roleNumber')"
          />
          <Input
            :label="'ECLI'"
            :id="'metadatas-ecli'"
            :value="metadatas.ecli"
            @onInput="onChange($event, 'ecli')"
          />
          <Input
            v-if="metadatas.jurisdictionType && metadatas.jurisdictionType.match(/c-const|c-e/)"
            :label="'Subdivision de l\'arrêt'"
            :id="'metadatas-number'"
            :value="metadatas.subdivision"
            @onInput="onChange($event, 'subdivision')"
          />
        </FormRow>

        <template v-if="metadatas.jurisdiction === 'c-e' || metadatas.jurisdiction === 'c-const'">
          <hr/>
          <FormRow>
            <Input
              :label="'Numéro officiel de l\'arrêt'"
              :id="'metadatas-stopNumber'"
              :value="metadatas.stopNumber"
              @onInput="onChange($event, 'stopNumber')"
            />
            <Input
              :label="'Subdivision de l\'arrêt'"
              :id="'metadatas-stopSubdivision'"
              :value="metadatas.stopSubdivision"
              @onInput="onChange($event, 'stopSubdivision')"
            />
          </FormRow>
        </template>
      </template>

      <!-- BE - Doctrine -->
      <template v-if="metadatas.country === 'be' && metadatas.type === 'doctrine'">
        <hr/>
        <FormRow>
          <Select
            :label="'Nature'"
            :options="be.doctrine.natures"
            :value="metadatas.nature"
            :placeholder="'Sélectionner la nature'"
            :allowNull="true"
            :hasSearch="true"
            @onChange="onChange($event, 'nature')"
          />
        </FormRow>

        <FormRow>
          <Input
            :label="'Prénom de l\'auteur'"
            :id="'metadatas-author-firstname'"
            :value="metadatas.authorFirstname"
            @onInput="onChange($event, 'authorFirstname')"
          />
          <Input
            :label="'Nom de l\'auteur'"
            :id="'metadatas-author-lastname'"
            :value="metadatas.authorLastname"
            @onInput="onChange($event, 'authorLastname')"
          />
        </FormRow>

        <template v-if="metadatas.nature === 'monograph'">
          <FormRow>
            <Input
              :label="'Titre de l’ouvrage'"
              :id="'metadatas-title'"
              :value="metadatas.title"
              @onInput="onChange($event, 'title')"
            />
            <Input
              :label="'Édition'"
              :id="'metadatas-edition'"
              :value="metadatas.edition"
              @onInput="onChange($event, 'edition')"
            />
            <Input
              :label="'Lieu'"
              :id="'metadatas-location'"
              :value="metadatas.location"
              @onInput="onChange($event, 'location')"
            />
            <Input
              :label="'Maison d\'édition'"
              :id="'metadatas-publishingHouse'"
              :value="metadatas.publishingHouse"
              @onInput="onChange($event, 'publishingHouse')"
            />
            <Input
              :label="'Date de publication'"
              :type="'date'"
              :id="'metadatas-publication-date'"
              :value="metadatas.publicationDate"
              @onInput="onChange($event, 'publicationDate')"
            />
          </FormRow>
        </template>

        <template v-if="metadatas.nature === 'legal-encyclopedia'">
          <FormRow>
            <Input
              :label="'Titre de la contribution'"
              :id="'metadatas-title'"
              :value="metadatas.title"
              @onInput="onChange($event, 'title')"
            />
            <Input
              :label="'Nom de l\'encyclopédie'"
              :id="'metadatas-journalName'"
              :value="metadatas.journalName"
              @onInput="onChange($event, 'journalName')"
            />
            <Input
              :label="'Tome'"
              :id="'metadatas-tome'"
              :value="metadatas.tome"
              @onInput="onChange($event, 'tome')"
            />
            <Input
              :label="'Année'"
              :id="'metadatas-year'"
              :value="metadatas.year"
              @onInput="onChange($event, 'year')"
            />
          </FormRow>
        </template>

        <template v-if="metadatas.nature === 'periodicals'">
          <FormRow>
            <Input
              :label="'Titre de la contribution'"
              :id="'metadatas-title'"
              :value="metadatas.title"
              @onInput="onChange($event, 'title')"
            />
            <Input
              :label="'Nom de la revue'"
              :id="'metadatas-journalName'"
              :value="metadatas.journalName"
              @onInput="onChange($event, 'journalName')"
            />
            <Input
              :label="'Année de la revue'"
              :id="'metadatas-year'"
              :value="metadatas.year"
              @onInput="onChange($event, 'year')"
            />
          </FormRow>
        </template>

        <template v-if="metadatas.nature === 'stop-note'">
          <FormRow>
            <Input
              :label="'Titre de la note'"
              :id="'metadatas-title'"
              :value="metadatas.title"
              @onInput="onChange($event, 'title')"
            />
            <Input
              :label="'Référence de la décision de justice'"
              :id="'metadatas-decisionReference'"
              :value="metadatas.decisionReference"
              @onInput="onChange($event, 'decisionReference')"
            />
            <Input
              :label="'Nom de la revue'"
              :id="'metadatas-journalName'"
              :value="metadatas.journalName"
              @onInput="onChange($event, 'journalName')"
            />
            <Input
              :label="'Année de la revue'"
              :id="'metadatas-year'"
              :value="metadatas.year"
              @onInput="onChange($event, 'year')"
            />
          </FormRow>
        </template>

        <template v-if="metadatas.nature === 'jurisprudence-review'">
          <FormRow>
            <Input
              :label="'Titre de la chronique / de l’examen'"
              :id="'metadatas-title'"
              :value="metadatas.title"
              @onInput="onChange($event, 'title')"
            />
            <Input
              :label="'Nom de la revue'"
              :id="'metadatas-journalName'"
              :value="metadatas.journalName"
              @onInput="onChange($event, 'journalName')"
            />
            <Input
              :label="'Année de la revue'"
              :id="'metadatas-year'"
              :value="metadatas.year"
              @onInput="onChange($event, 'year')"
            />
          </FormRow>
        </template>

        <template v-if="metadatas.nature === 'study'">
          <FormRow>
            <Input
              :label="'Titre de l\'étude'"
              :id="'metadatas-title'"
              :value="metadatas.title"
              @onInput="onChange($event, 'title')"
            />
            <Input
              :label="'Titre de l\'ouvrage'"
              :id="'metadatas-journalName'"
              :value="metadatas.journalName"
              @onInput="onChange($event, 'journalName')"
            />
            <Input
              :label="'Lieu'"
              :id="'metadatas-location'"
              :value="metadatas.location"
              @onInput="onChange($event, 'location')"
            />
            <Input
              :label="'Maison d\'édition'"
              :id="'metadatas-publishingHouse'"
              :value="metadatas.publishingHouse"
              @onInput="onChange($event, 'publishingHouse')"
            />
            <Input
              :label="'Année'"
              :id="'metadatas-year'"
              :value="metadatas.year"
              @onInput="onChange($event, 'year')"
            />
          </FormRow>
        </template>

        <template v-if="metadatas.nature === 'publication'">
          <FormRow>
            <Select
              :label="'Type de publication'"
              :options="be.doctrine.publicationTypes"
              :value="metadatas.publicationType"
              :placeholder="'Sélectionner le type'"
              :allowNull="true"
              :hasSearch="true"
              @onChange="onChange($event, 'publicationType')"
            />
            <Input
              :label="'Titre de la publication'"
              :id="'metadatas-title'"
              :value="metadatas.title"
              @onInput="onChange($event, 'title')"
            />
            <Input
              :label="'Numéro ou référence'"
              :id="'metadatas-number'"
              :value="metadatas.number"
              @onInput="onChange($event, 'number')"
            />
            <Input
              :label="'Date de publication'"
              :type="'date'"
              :id="'metadatas-publication-date'"
              :value="metadatas.publicationDate"
              @onInput="onChange($event, 'publicationDate')"
            />
          </FormRow>
        </template>
      </template>

      <!-- BE - Contract -->
      <template v-if="metadatas.country === 'be' && metadatas.type && metadatas.type.match(/contract|document|note|conclusion|quote/)">
        <hr/>
        <FormRow>
          <Input
            :label="'Prénom de l\'auteur'"
            :id="'metadatas-author-firstname'"
            :value="metadatas.authorFirstname"
            @onInput="onChange($event, 'authorFirstname')"
          />
          <Input
            :label="'Nom de l\'auteur'"
            :id="'metadatas-author-lastname'"
            :value="metadatas.authorLastname"
            @onInput="onChange($event, 'authorLastname')"
          />
          <Input
            :label="'Date de rédaction'"
            :type="'date'"
            :id="'metadatas-redaction-date'"
            :value="metadatas.redactionDate"
            @onInput="onChange($event, 'redactionDate')"
          />
        </FormRow>
      </template>

      <!-- EU - Legislation -->
      <template v-if="metadatas.type === 'legislation' && metadatas.country === 'eu'">
        <hr/>
        <!-- <h2 class="form-subtitle">Concernant la mesure</h2> -->
        <FormRow>
          <Select
            :label="'Nature'"
            :options="eu.legislation.natures"
            :value="metadatas.nature"
            :placeholder="'Sélectionner la nature'"
            :allowNull="true"
            @onChange="onChange($event, 'nature')"
          />
          <Select
            :label="'Institution'"
            :options="eu.legislation.institutions"
            :value="metadatas.institution"
            :placeholder="'Sélectionner l\'institution'"
            :allowNull="true"
            @onChange="onChange($event, 'institution')"
          />
          <Input
            :label="'Date'"
            :type="'date'"
            :id="'metadatas-legislation-date'"
            :value="metadatas.legislationDate"
            @onInput="onChange($event, 'legislationDate')"
          />
          <Input
            :label="'Intitulé'"
            :id="'metadatas-title'"
            :value="metadatas.title"
            @onInput="onChange($event, 'title')"
          />
          <Input
            :label="'Numéro'"
            :id="'metadatas-number'"
            :value="metadatas.number"
            @onInput="onChange($event, 'number')"
          />
          <Select
            :label="'Abréviation'"
            :options="eu.legislation.abbr"
            :value="metadatas.abbr"
            :placeholder="'Sélectionner l\'abréviation'"
            :allowNull="true"
            @onChange="onChange($event, 'abbr')"
          />
        </FormRow>
        <FormRow>
          <Input
            :label="'Série du journal officiel'"
            :id="'metadatas-journal-serie'"
            :value="metadatas.journalSerie"
            @onInput="onChange($event, 'journalSerie')"
          />
          <Input
            :label="'Numéro du journal officiel'"
            :id="'metadatas-journal-number'"
            :value="metadatas.journalNumber"
            @onInput="onChange($event, 'journalNumber')"
          />
          <Input
            :label="'Date du journal officiel'"
            :type="'date'"
            :id="'metadatas-journal-date'"
            :value="metadatas.journalDate"
            @onInput="onChange($event, 'journalDate')"
          />
        </FormRow>
      </template>

      <span
        v-if="metadatas.reference"
        class="form-ref"
      >
        {{ metadatas.reference }}
      </span>
    </template>
  </div>
</template>

<script>
// import axios from 'axios';
import Pills from '@/components/elements/Pills';
import Input from '@/components/elements/Input';
import Radio from '@/components/elements/Radio';
import Select from '@/components/elements/Select';
import FormRow from '@/components/modules/FormRow';
import getRef from '@/data/formaters/reference';

import metadatas from '@/data/static/metadatas';
import { calls, adminCalls, endpoints } from '@/services/calls';

export default {
  name: 'MetadataForm',
  components: {
    Pills,
    Input,
    Radio,
    Select,
    FormRow
  },
  props: {
    editing: {
      type: Boolean,
      default: false
    },
    full: {
      type: Boolean,
      default: true
    },
    metadatas: {
      type: Object,
      default: () => {}
    }
  },
  beforeMount() {
    const { role, id, lawyer_id, name } = this.$store.state.user.data;

    if (role === 'admin') {
      if(!this.editing) this.onChange('public', 'visibility');
    } else {
      this.onChange('private', 'visibility');
      this.onChange('lawyer', 'source');

      if (lawyer_id) {
        const lawyerSource = { id, lawyer_id, name };
        this.onChange(lawyerSource, 'lawyerSource');
      }
    }
  },
  data() {
    return {
      updating: false,
      uploadPopup: false,
      types: [...metadatas.types],
      countries: [...metadatas.countries],
      languages: [...metadatas.languages],
      sources: [...metadatas.sources],
      visibilities: JSON.parse(JSON.stringify([...metadatas.visibilities])),
      be: {...metadatas.be},
      eu: {...metadatas.eu},
      lawyers: [],
      lawyersLoad: false,
      users: [],
      usersLoad: false,
      lawcases: [],
      lawcasesLoad: false
    }
  },
  computed: {
    userSourceID() {
      const { lawyerSource, source } = this.metadatas;
      if (!lawyerSource) return null;
      return lawyerSource.id;
    },
    lawyerSourceID() {
      const { lawyerSource } = this.metadatas;
      if (!lawyerSource) return null;
      return lawyerSource.lawyer_id;
    },
    lawyerSourceName() {
      const { lawyerSource } = this.metadatas;
      if (!lawyerSource) return null;
      return lawyerSource.name;
    },
    lawcasesID() {
      // eslint-disable-next-line
      const { lawcases } = this.metadatas;
      if (!lawcases) return null;
      return lawcases.map(l => l.id);
    },
    jurisdictionsName() {
      const names = this.be.jurisprudence.jurisdictionsName;
      return names.filter(n => n.jurisdictionType === this.metadatas.jurisdictionType);
    },
    displayedVisibilities() {
      const { visibilities } = this;
      // return visibilities;
      return visibilities.map(v => {
        v.disabled = v.value === 'private' && this.metadatas.source === 'lawyer';
        return v;
      });
    }
  },
  methods: {
    onChange(value, type) {
      this.$emit('onChange', { value, type });

      if (type === 'country' || type === 'type') {
        const { country, type } = this.metadatas;

        if (country === 'be' && type === 'legislation') {
          this.$emit('onChange', { value: 'mb', type: 'collection' });
        }
      }

      if (type === 'source') {
        this.$emit('onChange', { value: null, type: 'lawyerSource' });
        if (value === 'lawyer') this.$emit('onChange', { value: 'public', type: 'visibility' });
      }

      const reference = getRef(this.metadatas);
      this.$emit('onChange', { value: reference, type: 'reference' });
    },
    onUserChange(value) {
      if (!value) {
        this.$emit('onChange', { value, type: 'lawyerSource' });
        return;
      }

      const user = this.users.filter(u => u.value === value);
      this.$emit('onChange', {
        value: user[0].data,
        type: 'lawyerSource'
      });
    },
    async onUserSearch(value) {
      if (!value || value.length < 3) {
        this.users = [];
        return;
      }

      this.usersLoad = true;
      try {
        // Error here
        const request = await adminCalls.get(
          "getUsers", { params: { filter: value } }
        );

        if (request.status != 'error') {
          const { data } = request;
          this.users = data.map(l => {
            return {
              label: l.name,
              value: l.id,
              data: { ...l }
            }
          });
        }
      } catch (error) {
        alert(error);
      }

      this.usersLoad = false;
    },
    onLawyerChange(value) {
      if (!value) {
        this.$emit('onChange', { value, type: 'lawyerSource' });
        return;
      }

      const lawyer = this.lawyers.filter(l => l.value === value);
      this.$emit('onChange', {
        value: {
          id: this.$store.state.user.data.id,
          lawyer_id: lawyer[0].data.id,
          name: lawyer[0].label
        },
        type: 'lawyerSource'
      });
    },
    async onLawyerSearch(value) {
      if (!value || value.length < 3) {
        this.lawyers = [];
        return;
      }

      this.lawyersLoad = true;
      try {
        // Error here
        const request = await adminCalls.get(
          "getLawyers",
          {
            params: {
              filter: value
            }
          }
        );
        // const request = await calls.get("getLawyers", { filter: value });
        // const request = await calls.post("getLawyers", { terms: value });

        if (request.status != 'error') {
          const { data } = request;
          // console.log('data :', data);
          this.lawyers = data.map(l => {
            return {
              label: l.firstname + ' ' + l.lastname,
              // label: l.name,
              value: l.id,
              data: { ...l }
            }
          });
        }
      } catch (error) {
        alert(error);
      }

      this.lawyersLoad = false;
    },
    onLawcaseChange(value) {
      const lawcase = this.lawcases.filter(l => l.value === value);
      if (!lawcase || !lawcase.length) {
         this.$emit('onChange', {
          value: { id: value, },
          type: 'lawcases'
        });
        return;
      }

      this.$emit('onChange', {
        value: {
          id: lawcase[0].value,
          content: lawcase[0].label
        },
        type: 'lawcases'
      });
    },
    async onLawcaseSearch(value) {
       if (!value || value.length < 3) {
        this.lawcases = [];
        return;
      }

      this.lawcasesLoad = true;
      try {
        const request = await calls.post("getLawcases", {
          lang: 'fr',
          terms: value,
          penal: true
        });

        if (request.status != 'error') {
          const { data } = request;
          this.lawcases = data.map(l => {
            return {
              label: l.content,
              value: l.id
            }
          });
        }
      } catch (error) {
        alert(error);
      }

      this.lawcasesLoad = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.form {
  margin-bottom: 16px;

  &:not(:first-child) {
    margin-top: 16px;
  }

  hr {
    width: 100%;
    height: 1px;
    margin-top: 20px;
    margin-bottom: 0;
    border: 0;
    background-color: var(--grey-lightest);
  }

  &-ref {
    display: block;
    color: var(--grey);
    margin-top: 16px;
  }
}
</style>