<template>
  <Popup
    v-if="visible"
    :title="$t('home.upload.title')"
    @onClose="close"
  >
    <p>{{ $t('home.upload.text') }}</p>

    <Upload
      :files="files"
      :multiple="multiple"
      @add="onUploadChange"
      @import="onImport"
      @reset="onReset"
    />

    <MetadataForm
      :full="!multiple"
      :metadatas="metadatas"
      @onChange="onChange"
    />

    <p
      v-if="warning"
      class="upload-warning"
    >
      {{ $t('upload.warning') }}
    </p>

    <Btn
      :disabled="!files || !files.length || loading"
      @onClick="onImport"
    >
      {{ $t('actions.continue') }}
    </Btn>
  </Popup>
</template>

<script>
import Btn from '@/components/elements/Btn';
import Loader from '@/components/elements/Loader';
import Upload from '@/components/modules/Upload';
import Popup from '@/components/partials/Popup';
import MetadataForm from '@/components/partials/MetadataForm';

import metadatas from '@/data/static/metadatas';
import globals from '@/data/static/globals.json';

export default {
  name: 'UploadPopup',
  props: {
    multiple: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Btn,
    Upload,
    Popup,
    Loader,
    MetadataForm
  },
  beforeMount() {
    if (!this.multiple) {
      // If it's the admin, we set the source
      this.$store.commit('upload/updateMetadata', {
        type: 'source',
        value: 'symplicy'
      });
    }
  },
  data() {
    return {
      updating: false,
      uploadPopup: false,
      types: [...metadatas.types],
      countries: [...metadatas.countries],
      visibilities: [...metadatas.visibilities],
    }
  },
  computed: {
    visible() {
      return this.$store.state.upload.visible;
    },
    files() {
      if (this.multiple) return this.$store.state.upload.files;
      return this.$store.state.upload.file ? [this.$store.state.upload.file] : [];
    },
    metadatas() {
      return this.$store.state.upload.metadatas;
    },
    warning() {
      const { role } = this.$store.state.user.data;
      const { visibility } = this.$store.state.upload.metadatas;
      return role === 'user' && visibility === 'public';
    },
    loading() {
      return this.$store.state.upload.requests !== 0;
    }
  },
  methods: {
    onUploadChange(files) {
      if (this.multiple) {
        this.$store.commit('upload/updateFiles', files);
        return;
      }

      this.$store.commit('upload/updateFile', files);
    },
    onReset() {
      this.$store.commit('upload/updateFile', null);
      this.$store.commit('upload/updateFiles', []);
    },
    close() {
      this.$store.commit('upload/toggle');
      this.$store.commit('upload/reset');
    },
    onChange({ value, type }) {
      if (type === 'lawcases') {
        this.$store.commit('upload/updateMetadataArray', { type, item: value });
      } else {
        this.$store.commit('upload/updateMetadata', { type, value });
      }
    },
    async onImport() {
      if (this.loading) return;
      const request = await this.$store.dispatch(`upload/uploadFile${this.multiple ? 's': ''}`);

      if (request.status === 'success') {
        // Check to add status if admin
        const stats = {
          Admin: 'processed',
          Process: 'pending|success',
          Faileds: 'failure'
        };

        const { name } = this.$route;
        let page = this.$route.query?.page || 0;

        if (stats[name]) {
          this.$store.dispatch('documents/getDocuments', {
            offset: (page - 1) * globals.pagination.perPage,
            status: stats[name]
          });
        } else {
          this.$store.dispatch('documents/getDocuments', {});
        }

        this.$store.commit('upload/toggle');
        this.$store.commit('upload/reset');
        // return;
      }

      // this.$store.dispatch('notifications/addNotifs', [
      //     { text: 'L\'importation a échoué', type: 'error' }
      //   ]);
    },
  }
};
</script>

<style lang="scss" scoped>
.upload {
  + .button {
    margin-top: 24px;
  }

  &-warning {
    @include padding(null 24px);
    margin-bottom: 16px;
    color: var(--alert);
    font-size: rem(17px);
    font-weight: 700;
    text-align: center;
    line-height: 1.2;
  }
}
</style>